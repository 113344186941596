import React, { Component } from 'react'
import 'whatwg-fetch' // Fetch Polyfill

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      subject: '',
      phone: '',
      message: '',
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  onSubmit = e => {
    const { name, email, subject, phone, message } = this.state
    let userData = {}
    if (name && email && subject && phone && message) {
      userData = {
        name,
        email,
        subject,
        phone,
        message,
      }
    } else {
      userData = {
        name,
        email,
        message,
      }
    }

    console.log('currentUser>', userData)
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': 'requestCallback', ...userData }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    e.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      subject: '',
      phone: '',
      message: '',
    })
  }

  renderFooterContact() {
    return (
      <form
        name="footer"
        method="post"
        className="wpcf7-form"
        noValidate="novalidate"
        data-netlify="true"
        data-netlify-honeypot="bot"
        onSubmit={this.onSubmit}
      >
        <div style={{ display: 'none' }}>
          <input
            type="hidden"
            name="Footer Location"
            value={`https://www.boragrowth.com${this.props.location}`}
          />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot" onChange={this.onChange} />
            </label>
          </p>
        </div>
        <div className="gdlr-core-input-wrap gdlr-core-small gdlr-core-full-width gdlr-core-with-column gdlr-core-no-border">
          <div className="gdlr-core-column-60">
            <span className="wpcf7-form-control-wrap your-name">
              <input
                type="text"
                name="name"
                size={40}
                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                aria-required="true"
                aria-invalid="false"
                placeholder="Full Name*"
                value={this.state.name}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div className="clear" />
          <div className="gdlr-core-column-60">
            <span className="wpcf7-form-control-wrap your-email">
              <input
                type="email"
                name="email"
                size={40}
                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                aria-required="true"
                aria-invalid="false"
                placeholder="Email*"
                value={this.state.email}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div className="clear" />
          <div className="gdlr-core-column-60">
            <span className="wpcf7-form-control-wrap your-message">
              <textarea
                name="message"
                cols={40}
                rows={10}
                className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                aria-required="true"
                aria-invalid="false"
                placeholder="Message*"
                value={this.state.message}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div className="gdlr-core-column-60 gdlr-core-left-align">
            <input
              type="submit"
              defaultValue="Submit Now"
              className="wpcf7-form-control wpcf7-submit gdlr-core-small"
            />
          </div>
        </div>
      </form>
    )
  }
  render() {
    if (this.props.renderFooterContact) {
      return this.renderFooterContact()
    }
    return (
      <form
        name="requestCallback"
        method="post"
        className="wpcf7-form"
        data-netlify="true"
        data-netlify-honeypot="bot"
        onSubmit={this.onSubmit}
      >
        <div style={{ display: 'none' }}>
          <input
            type="hidden"
            name="Location"
            value={`https://www.boragrowth.com${this.props.location}`}
          />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot" onChange={this.onChange} />
            </label>
          </p>
        </div>
        <div className="gdlr-core-input-wrap gdlr-core-large gdlr-core-full-width gdlr-core-with-column gdlr-core-no-border ">
          <div className="gdlr-core-column-30">
            <span className="wpcf7-form-control-wrap your-name">
              <input
                type="text"
                name="name"
                placeholder="Full Name*"
                value={this.state.name}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div className="gdlr-core-column-30">
            <span className="wpcf7-form-control-wrap your-email">
              <input
                type="email"
                name="email"
                size={40}
                placeholder="Email*"
                value={this.state.email}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div className="clear" />
          <div className="gdlr-core-column-30">
            <span className="wpcf7-form-control-wrap your-subject">
              <input
                type="text"
                name="subject"
                size={40}
                placeholder="Subject*"
                value={this.state.subject}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div className="gdlr-core-column-30">
            <span className="wpcf7-form-control-wrap your-phone">
              <input
                type="tel"
                name="phone"
                size={40}
                placeholder="Phone Number"
                value={this.state.phone}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div className="clear" />
          <div className="gdlr-core-column-60">
            <span className="wpcf7-form-control-wrap your-message">
              <textarea
                type="text"
                name="message"
                cols={40}
                rows={10}
                className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                placeholder="Message*"
                value={this.state.message}
                onChange={this.onChange}
                required
              />
            </span>
          </div>
          <div
            className="gdlr-core-column-60"
            style={this.props.centreButton ? { textAlign: 'center' } : null}
          >
            <input
              type="submit"
              defaultValue="Submit Now"
              className="wpcf7-form-control wpcf7-submit gdlr-core-round-button"
            />
          </div>
        </div>
        <div className="wpcf7-response-output wpcf7-display-none" />
      </form>
    )
  }
}
