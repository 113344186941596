import React, { Component } from 'react'
import { Link } from 'gatsby'
export default class Navbar extends Component {
  render() {
    const logo = require('../assets/images/bora_ld_white_FA.png')

    return (
      <div className="bora-body-wrapper clearfix  bora-with-transparent-header bora-with-frame">
        <div className="bora-header-background-transparent">
          <div className="bora-top-bar">
            <div className="bora-top-bar-background" />
            <div className="bora-top-bar-container clearfix bora-container ">
              <div className="bora-top-bar-left bora-item-pdlr">
                <span className="gdlr-core-dropdown-tab gdlr-core-js clearfix">
                  <span className="gdlr-core-dropdown-tab-content-wrap">
                    <span
                      className="gdlr-core-dropdown-tab-content gdlr-core-active"
                      data-index={0}
                    >
                      {' '}
                      <i
                        className="fa fa-clock-o"
                        style={{
                          fontSize: 17,
                          color: '#a5bfff',
                          marginRight: 10,
                        }}
                      />{' '}
                      <strong>Mon - Fri 9:00-17:00</strong>{' '}
                      <i
                        className="fa fa-phone"
                        style={{
                          fontSize: 17,
                          color: '#a5bfff',
                          marginLeft: 20,
                          marginRight: 10,
                        }}
                      />
                      <strong>(+27) - 214182812</strong>{' '}
                    </span>
                  </span>
                </span>
              </div>
              <div className="bora-top-bar-right bora-item-pdlr">
                <div className="bora-top-bar-right-text">
                  <i
                    className="fa fa-envelope-open-o"
                    style={{
                      fontSize: 17,
                      color: '#a5bfff',
                      marginLeft: 20,
                      marginRight: 14,
                    }}
                  />
                  <strong>info@boragrowth.com</strong>
                </div>
                <span className="bora-top-bar-divider bora-left-margin" />
                <div className="bora-top-bar-right-social">
                  <a
                    href="https://www.linkedin.com/company/bora-growth-partners/"
                    target="_blank"
                    className="bora-top-bar-social-icon"
                    title="linkedin"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                  <a
                    href="https://twitter.com/BoraGrowth"
                    target="_blank"
                    className="bora-top-bar-social-icon"
                    title="twitter"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                  <a
                    href="https://www.facebook.com/boragrowth"
                    target="_blank"
                    className="bora-top-bar-social-icon"
                    title="facebook"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a
                    href="https://instagram.com/BoraGrowth"
                    target="_blank"
                    className="bora-top-bar-social-icon"
                    title="instagram"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <header
            className="bora-header-wrap bora-header-style-plain  bora-style-menu-right bora-sticky-navigation bora-style-slide"
            data-navigation-offset="75px"
          >
            <div className="bora-header-background" />
            <div className="bora-header-container  bora-container">
              <div className="bora-header-container-inner clearfix">
                <div className="bora-logo  bora-item-pdlr">
                  <div className="bora-logo-inner">
                    <Link to="/">
                      <img src={logo} style={{ display: '-webkit-box' }} />
                    </Link>
                  </div>
                </div>
                <div className="bora-navigation bora-item-pdlr clearfix ">
                  <div className="bora-main-menu" id="bora-main-menu">
                    <ul id="menu-main-navigation-1" className="sf-menu">
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/about">About</Link>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        {/* <Link to="/blog">Blog</Link> */}
                        <a href="http://blog.boragrowth.com/" target="_blank">
                          Blog
                        </a>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    )
  }
}
