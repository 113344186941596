import React, { Component } from 'react'

export default class Hero extends Component {
  renderOriginal() {
    const landingImage = require('../assets/images/landing.jpg')

    return (
      <div>
        <div
          className="gdlr-core-pbf-wrapper "
          style={{
            paddingTop: '420px',
            paddingHorizontal: '0px',
            paddingBottom: '135px',
          }}
          id="gdlr-core-wrapper-1"
        >
          <div className="gdlr-core-pbf-background-wrap">
            <div
              className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
              style={{
                backgroundImage: `url(${
                  this.props.heroImage ? this.props.heroImage : landingImage
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: this.props.withOpacity
                  ? 'rgba(8, 169, 204, 0.5)'
                  : '',
                backgroundBlendMode: this.props.withOpacity ? 'multiply' : '',
              }}
              data-parallax-speed="0.2"
            />
          </div>
          <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div className="gdlr-core-pbf-element">
                <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                  <div className="gdlr-core-title-item-title-wrap ">
                    <h3
                      className="gdlr-core-title-item-title gdlr-core-skin-title "
                      style={{
                        fontSize: 93,
                        fontWeight: 600,
                        letterSpacing: 0,
                        textTransform: 'none',
                        color: '#ffffff',
                      }}
                    >
                      {this.props.title}
                      <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                    </h3>
                  </div>
                  <span
                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                    style={{
                      fontSize: 14,
                      fontStyle: 'normal',
                      letterSpacing: 2,
                      textTransform: 'uppercase',
                      color: '#e0e0e0',
                    }}
                  >
                    {this.props.description}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderOriginal()
  }
}
