import React, { Component } from 'react'

export default class SocialLinkIcon extends Component {
  render() {
    return (
      <a
        href={this.props.linkTo}
        target="_blank"
        className={this.props.className}
        title={this.props.title}
        style={this.props.style}
      >
        <i className={this.props.icon} />
      </a>
    )
  }
}
