import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import MobileNavbar from '../MobileNavbar'
import Helmet from 'react-helmet'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div className="home page-template-default page page-id-5514 gdlr-core-body bora-body bora-body-front bora-full  bora-with-sticky-navigation gdlr-core-link-to-lightbox">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Bora Growth Partners</title>
        </Helmet>
        <MobileNavbar />
        <div className="bora-body-outer-wrapper clearfix bora-with-transparent-header bora-with-frame">
          <Navbar />
          <div className="bora-page-wrapper" id="bora-page-wrapper">
            {children}
          </div>
          <Footer location={this.props.location} />
        </div>
      </div>
    )
  }
}

export default Template
