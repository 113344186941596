import React, { Component } from 'react'
import { Link } from 'gatsby'
import SocialLinkIcon from './Social/SocialLinkIcon'
import ContactForm from './Common/ContactForm'
export default class Footer extends Component {
  render() {
    const footerLogo = require('../assets/images/bora_ld_white_FA.png')
    return (
      <div>
        <footer>
          <div className="bora-footer-wrapper ">
            <div className="bora-footer-container bora-container clearfix">
              <div className="bora-footer-column bora-item-pdlr bora-column-15">
                <div id="text-2" className="widget widget_text bora-widget">
                  <div className="textwidget">
                    <p>
                      <img src={footerLogo} />
                    </p>
                    <p>
                      <i className="fa fa-location-arrow" id="i_f47b_5" />&nbsp;
                      50 Long Street Cape Town, Western Cape, SA
                      <br />{' '}
                      <span
                        className="gdlr-core-space-shortcode"
                        id="span_f47b_8"
                      />{' '}
                      <i className="fa fa-phone" id="i_f47b_6" /> (+27) -
                      214182812 <br />
                      <i className="fa fa-phone" id="i_f47b_6" /> (+27) -
                      840344111
                      <br />{' '}
                      <span
                        className="gdlr-core-space-shortcode"
                        id="span_f47b_9"
                      />{' '}
                      <i className="fa fa-envelope-open-o" id="i_f47b_7" />{' '}
                      info@boragrowth.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="bora-footer-column bora-item-pdlr bora-column-15">
                <div
                  id="gdlr-core-recent-post-widget-3"
                  className="widget widget_gdlr-core-recent-post-widget bora-widget"
                >
                  <h3 className="bora-widget-title">Recent News</h3>
                  <div className="gdlr-core-recent-post-widget-wrap gdlr-core-style-2">
                    {/* <div className="gdlr-core-recent-post-widget clearfix">
                      <div className="gdlr-core-recent-post-widget-content">
                        <div className="gdlr-core-recent-post-widget-title">
                          <a href="#">I sink under the weight</a>
                        </div>
                        <div className="gdlr-core-recent-post-widget-info">
                          <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                            <span className="gdlr-core-head">
                              <i className="icon_clock_alt" />
                            </span>
                            <a href="#">June 6, 2016</a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="gdlr-core-recent-post-widget clearfix">
                      <div className="gdlr-core-recent-post-widget-content">
                        <div className="gdlr-core-recent-post-widget-title">
                          <a href="#">Possession of my entire soul</a>
                        </div>
                        <div className="gdlr-core-recent-post-widget-info">
                          <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                            <span className="gdlr-core-head">
                              <i className="icon_clock_alt" />
                            </span>
                            <a href="#">June 6, 2016</a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="gdlr-core-recent-post-widget clearfix">
                      <div className="gdlr-core-recent-post-widget-content">
                        <div className="gdlr-core-recent-post-widget-title">
                          <a href="#">I am so happy, my dear friend</a>
                        </div>
                        <div className="gdlr-core-recent-post-widget-info">
                          <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                            <span className="gdlr-core-head">
                              <i className="icon_clock_SocialLinkIcon" />
                            </span>
                            <a href="#">June 6, 2016</a>
                          </span>
                        </div>
                      </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="bora-footer-column bora-item-pdlr bora-column-15">
                <div
                  id="nav_menu-1"
                  className="widget widget_nav_menu bora-widget"
                >
                  <h3 className="bora-widget-title">Navigation</h3>
                  <div className="menu-useful-links-container">
                    <ul id="menu-useful-links" className="menu">
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/about">About us</Link>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/services">Our Services</Link>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li className="menu-item menu-item-home bora-normal-menu">
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bora-footer-column bora-item-pdlr bora-column-15">
                <div id="text-4" className="widget widget_text bora-widget">
                  <h3 className="bora-widget-title">Contact Form</h3>
                  <div className="textwidget">
                    <div role="form" className="wpcf7" lang="en-US" dir="ltr">
                      <div className="screen-reader-response" />
                      <ContactForm
                        location={
                          this.props.location
                            ? this.props.location.pathname
                            : null
                        }
                        renderFooterContact
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bora-copyright-wrapper">
            <div className="bora-copyright-container bora-container clearfix">
              <div className="bora-copyright-left bora-item-pdlr">
                Copyright &copy; {new Date().getFullYear()} Bora Growth
                Partners. All Rights Reserved.
              </div>
              <div className="bora-copyright-right bora-item-pdlr">
                <SocialLinkIcon
                  linkTo="https://www.linkedin.com/company/bora-growth-partners/"
                  target="_blank"
                  className="bora-top-bar-social-icon"
                  title="linkedin"
                  style={{ paddingRight: '5px' }}
                  icon="fa fa-linkedin"
                />

                <SocialLinkIcon
                  linkTo="https://twitter.com/BoraGrowth"
                  className="bora-top-bar-social-icon"
                  title="twitter"
                  style={{ paddingRight: '5px', textDecoration: 'none' }}
                  icon="fa fa-twitter"
                />

                <SocialLinkIcon
                  linkTo="https://www.facebook.com/boragrowth"
                  className="bora-top-bar-social-icon"
                  title="facebook"
                  style={{ paddingRight: '5px' }}
                  icon="fa fa-facebook"
                />

                <SocialLinkIcon
                  linkTo="https://instagram.com/BoraGrowth"
                  className="bora-top-bar-social-icon"
                  title="instagram"
                  style={{ paddingRight: '5px' }}
                  icon="fa fa-instagram"
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
