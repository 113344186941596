import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class MobileNavigation extends Component {
  render() {
    const logo = require('../assets/images/bora_ld_FA.png')

    return (
      <div className="bora-mobile-header-wrap">
        <div
          className="bora-mobile-header bora-header-background bora-style-slide"
          id="bora-mobile-header"
        >
          <div className="bora-mobile-header-container bora-container">
            <div className="bora-logo  bora-item-pdlr">
              <div className="bora-logo-inner">
                <Link to="/">
                  <img
                    src={logo}
                    width={211}
                    height={48}
                    style={{ display: '-webkit-box' }}
                  />
                </Link>
              </div>
            </div>
            <div className="bora-mobile-menu-right">
              <div className="bora-mobile-menu">
                <a
                  className="bora-mm-menu-button bora-mobile-menu-button bora-mobile-button-hamburger"
                  href="#bora-mobile-menu"
                  style={{ backgroundColor: '#0e758b' }}
                >
                  <span />
                </a>
                <div
                  className="bora-mm-menu-wrap bora-navigation-font"
                  id="bora-mobile-menu"
                  data-slide="right"
                >
                  <ul id="menu-main-navigation" className="m-menu">
                    <li className="menu-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/about">About</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/services">Services</Link>
                    </li>
                    <li className="menu-item">
                      {/* <Link to="/blog">Blog</Link> */}
                      <a href="http://blog.boragrowth.com/" target="_blank">
                        Blog
                      </a>
                    </li>
                    <li className="menu-item menu-item-home bora-normal-menu">
                      <Link to="/contact-us">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
